.login-container {
    height: calc(100vh - 150px);
    height: fit-content;
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;

    .text {
        color: var(--text-color-1);
        margin: 0 10px;
    }

    .box-container {
        background-color: var(--background-color);
        color: var(--text-color-1);
    }

    .login-page {
        background-color: var(--background-color);
        height: 100dvh;
        width: 100dvw;

        .left-section {
            height: 100dvh;
            width: 100dvw;
        }
    }
}