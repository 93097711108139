.analyze-view-container {
    background-color: var(--background-color-3);
    max-width: 60vw;
    width: clamp(20vw, 100%, 60vw);
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: fit-content;
    transition: all 0.5s;

    .file-container {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;
        background-color: var(--background-color-2);
        padding: 10px;
        border: 1px solid var(--border);
        border-radius: 10px;

        .file-name {
            margin: 0;
        }

        .file-size {
            color: var(--gray-500);
            font-weight: 400;
            font-size: 14px;
            margin: 0;
        }
    }

    .view-cotainer {
        display: flex;
        flex-direction: column;
        height: fit-content;
        gap: 10px;
        align-items: center;
        width: 100%;
        background-color: var(--background-color-2);
        padding: 10px;
        border: 1px solid var(--border);
        border-radius: 10px;

        .header {
            border-bottom: 1px solid var(--border);
            width: 100%;
            padding-bottom: 10px;
            font-weight: 500;
            font-size: 16px;
            margin: 0;
        }

        .inner-content {
            display: flex;
            width: 100%;
            flex-direction: column;
            .inner-header {
                width: 100%;
                padding-bottom: 10px;
                font-weight: 500;
                margin: 0;
            }
        }

        .exp-content {
            color: var(--gray-500);
            font-weight: 400;
            font-size: 14px;
            margin: 0;
        }

        .study-details {
            background-color: var(--background-color-3);
            width: 100%;
            padding: 10px;
            border-radius: 10px;

            .study-header {
                color: var(--gray-500);
                font-weight: 400;
                font-size: 16px;
                margin: 0;
            }

            .base-value {
                font-weight: 600;
                font-size: 20px;
            }

            .performance-content {
                margin-top: 10px;
                width: 100%;
                background-color: var(--background-color-2);
                border-radius: 10px;
                height: 100px;
            }
        }

    }
}

@media screen and (max-width: 600px) {
    .analyze-view-container {
        max-width: 100% !important;
        width: clamp(20vw, 100%, 100%) !important;
    }
}