.analyser-container {
    width: 100%;
    color: var(--text-color-1);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    align-self: center;

    .content-container {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        background-color: var(--background-color-2);
        height: fit-content;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        .lock-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(var(--color-4), 0.6);
            /* Semi-transparent white */
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            /* Make sure it's above other content */
            cursor: not-allowed;
        }

        .top-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: fit-content;
            gap: 10px;

            .header {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }

            .hint {
                color: var(--gray-500);
                font-weight: 400;
                font-size: 14px;
                margin: 0;
            }

            .file-list {
                padding: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .file-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    border: 1px solid var(--border);
                    padding: 0 10px;
                    border-radius: 20px;

                    .file-actions {
                        cursor: pointer;
                    }
                }
            }
        }

        .down-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .hint {
                margin: 0;
                color: var(--menu-item-color);
            }

            .analyze-button {
                background-color: var(--analyze-color-bg) !important;
                color: var(--analyze-color) !important;
                width: fit-content;
                box-shadow: none !important;
                border: 1px solid var(--analyze-color);
            }

            .active-button {
                width: fit-content !important;
            }
        }
    }

    &.view {
        height: 100%;
        justify-content: flex-start;
    }
}