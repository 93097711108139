.content-container {
    width: fit-content;
    height: 70dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    max-width: 60vw;

    .top-area {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .question-container {
            .question {
                margin: 0;
                font-weight: 600;
                font-size: 22px;
            }

            .hint {
                // margin: 0;
                color: var(--gray-500);
                font-weight: 400;
                font-size: 16px;
            }
        }

        .onboarding-date {
            max-width: 295px;
        }
    }

    .bottom-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .continue-button {
            width: fit-content;
            background-color: var(--prime-color);
        }
    }
}