.app-tabs{
    display: flex;
    gap: 10px;
    width: 100%;
    background-color: transparent;

    .each-tab{
        flex-grow: 1;
        border-radius: 10px;

        &.Mui-selected{
            color: var(--prime-color);
            background-color: #FFF7ED;
        }
    }
    .MuiTabs-indicator{
        display: none;
    }
}