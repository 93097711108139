.text-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm, 6px);
    align-self: stretch;
    color: var(--placeholder);

    .label {
        margin-top: 12px !important;
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-xxs, 2px);
        color: var(--placeholder);
        font-family: var(--Font-family-font-family-body, Inter);
        font-size: var(--Font-size-text-sm, 14px);
        font-style: normal;
        font-weight: 500;
        line-height: var(--Line-height-text-sm, 20px);
    }

    .hint {
        margin-bottom: 10px !important;
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-xxs, 2px);
        color: var(--placeholder);
        font-family: var(--Font-family-font-family-body, Inter);
        font-size: var(--Font-size-text-sm, 14px);
        font-style: normal;
        font-weight: 500;
        line-height: var(--Line-height-text-sm, 20px);
    }

    .field {
        display: flex;
        align-items: center;
        gap: var(--spacing-md, 8px);
        align-self: stretch;
        margin-bottom: 0px !important;

        .MuiInputBase-colorPrimary {
            border-radius: 10px;
            border-color: var(--field-border);
        }

        fieldset {
            border-color: var(--field-border) !important;
        }
    }

    .Mui-error {
        fieldset {
            border-color: #d32f2f !important;
        }

        &:hover {
            fieldset {
                border-color: #d32f2f !important;
            }
        }
    }

    .error-msg {
        color: red;
    }
}