.profile-container {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 200px 1fr;
    padding: 20px;
    background-color: var(--background-color-3);
    gap: 20px;

    .left-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .avatar-container {
            width: 100%;
            border-radius: 10px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: fit-content;
            justify-content: center;
            gap: 10px;
            background-color: var(--background-color-2);

            .profile-avatar {
                height: 100px;
                width: 100px;
                font-size: 24px;
                font-weight: 600;
            }

            .profile-details {
                display: flex;
                flex-direction: column;
                align-items: center;

                .username {
                    font-size: 20px;
                    font-weight: 500;
                    color: var(--text-color-1);
                    margin: 0;
                }

                .useremail {
                    color: var(--text-color-1);
                    font-size: 12px;
                    margin: 0;
                }
            }
        }

        .basic-details-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            border-radius: 10px;
            padding: 20px;
            background-color: var(--background-color-2);

            .header-text {
                font-size: 16px;
                margin: 0;
                font-weight: 500;
                color: var(--text-color-1);
            }

            .basic-details {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;

                .head {
                    color: var(--placeholder);
                    font-weight: 500;
                    margin: 0;
                }

                .data {
                    margin: 0;
                }
            }
        }
    }

    .tabs-container {
        height: fit-content;
        background-color: var(--background-color-2);
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .profile-content-container {
            height: fit-content;
        }

        .profile-tabs {
            align-items: stretch;

            .MuiTabs-fixed {
                .MuiTabs-flexContainer {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;

                    .each-tab {
                        width: 100%;
                        max-width: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .profile-container {
        grid-template-columns: 1fr;
        padding: 15px;
        gap: 15px;

        .left-container {
            .avatar-container {
                padding: 20px;
                flex-direction: row;
                justify-content: flex-start;
                gap: 20px;

                .profile-avatar {
                    width: 80px;
                    height: 80px;
                }

                .profile-details {
                    align-items: flex-start;
                }
            }
        }

        .tabs-container {
            padding: 15px;

            .profile-tabs {
                .MuiTabs-fixed {
                    .MuiTabs-flexContainer {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .profile-container {
        padding: 10px;
        gap: 10px;

        .left-container {
            .avatar-container {
                padding: 15px;

                .profile-avatar {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        .tabs-container {
            padding: 10px;
        }
    }
}