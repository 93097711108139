.greeting-text{
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-weight: 500;
}

.greeting-container{
    color: var(--text-color-1);

    h4 {
        margin: 0;
        font-weight: 400;
    }
}