.dashboard-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    position: relative;

    .chart-container {
        width: 100%;
        height: 100%;
        padding: 10px 0;
        display: grid;
        grid-template-rows: 100px 1fr minmax(200px, 1fr);
        gap: 10px;
        
        .grid-container-1 {
            gap: 10px;
            display: grid;
            grid-template-columns: 1.5fr 1fr 2fr;
            height: 100%;
        }
        
        .grid-container-2 {
            gap: 10px;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-rows: 1fr;
            height: 100%;
        }
    }
}

@media screen and (max-width: 1200px) {
    .dashboard-container {
        padding: 15px;

        .chart-container {
            .grid-container-1 {
                grid-template-columns: 1fr 1fr;
            }
            
            .grid-container-2 {
                grid-template-columns: 1fr;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .dashboard-container {
        padding: 10px;

        .chart-container {
            grid-template-rows: auto;
            gap: 15px;
            
            .grid-container-1 {
                grid-template-columns: 1fr;
            }
            
            .grid-container-2 {
                grid-template-columns: 1fr;
                grid-template-rows: auto;
            }
        }
    }
}