.custom-toster {

    .Toastify__close-button {
        color: #141E2D;
        opacity: unset;
        align-self: auto;

        svg {
            height: 20px !important;
            width: 20px !important;
        }
    }

    .Toastify__toast {
        min-height: fit-content !important;
    }

    .Toastify__toast-theme--colored {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: -0.02em;
        text-align: left;
        background: #141E2D;
        color: black !important;
    }

    .Toastify__toast--success {
        background: linear-gradient(0deg, #05CD99, #05CD99) !important;

        border: 1px solid #A3AED066;
        box-shadow: -2px 4px 50px 0px #05CD9966;

    }

    .Toastify__toast--error {
        border-radius: 10px !important;
        border: 1px solid rgba(163, 174, 208, 0.40) !important;
        background: #E84040 !important;
        box-shadow: -2px 4px 50px 0px rgba(232, 64, 64, 0.40) !important;
    }

    .Toastify__toast--warning {
        background: linear-gradient(0deg, #FFC940, #FFC940) !important;
        border: 1px solid #A3AED066;
        box-shadow: -2px 4px 50px 0px rgba(255, 201, 64, 0.40);
    }

    .Toastify__toast--info {
        background: linear-gradient(0deg, #A3AED0, #A3AED0) !important;
        box-shadow: -2px 4px 50px 0px #00000066;
        border: 1px solid #A3AED066
    }

    .Toastify__toast--default {
        background: linear-gradient(0deg, #0B111A, #0B111A) !important;
        box-shadow: -2px 4px 50px 0px #00000066;
        border: 1px solid #A3AED066;
        color: #fff !important;

        .Toastify__close-button--colored {
            color: #fff !important
        }

        .Toastify__toast-theme--colored {
            color: #fff !important
        }
    }
}