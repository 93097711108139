.study-plan-container {
    width: calc(100vw - 280px);
    color: var(--text-color-1);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    align-self: center;

    .study-plan-view-container {
        background-color: var(--background-color-3);
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;

        .file-container {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background-color: var(--background-color-2);
            padding: 30px;
            border: 1px solid var(--border);
            border-radius: 10px;

            .left-space {
                margin: 0;
                display: flex;
                align-items: center;
                gap: 20px;

                .icon-container {
                    padding: 15px 15px 12px 15px;
                    background-color: var(--profile-bg);
                    border-radius: 5px;
                    width: fit-content;
                    height: fit-content;
                }

                .file-details {
                    .file-content {
                        color: var(--text-color-1);
                        margin: 0;
                        font-size: 12px;

                        &.bold {
                            font-weight: 500;
                            font-size: 26px;
                        }
                    }
                }
            }
        }

        .calendar-container {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            min-height: 300px;
            background-color: var(--background-color-2);
            padding: 30px;
            border: 1px solid var(--border);
            border-radius: 10px;
        }
    }
}

.ama-container {
  min-height: 100vh;
  background-color: var(--background-color-1);
  color: var(--text-color-1);
  padding: 2rem;
  font-family: Arial, sans-serif;

  .content {
    margin: 0 auto;
    
    .header {
      text-align: center;
      margin-bottom: 4rem;
      
      .orange-text {
        color: var(--prime-color);
      }
    }

    .chat-box {
      background-color: var(--background-color-2);
      border-radius: 8px;
      padding: 1rem;
      margin-bottom: 2rem;

      textarea {
        width: 100%;
        background-color: transparent;
        border: none;
        color: var(--text-color-1);
        font-size: 1rem;
        resize: none;
        outline: none;
        min-height: 100px;
        margin-bottom: 1rem;
        font-family: inherit;

        &::placeholder {
          color: var(--text-color-3);
        }

        &:focus {
          outline: none;
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }

      .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ai-info {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .ai-name {
            color: var(--text-color-2);
          }

          .icon-container {
            background-color: var(--background-color-3);
            border-radius: 50%;
            padding: 0.25rem;

            svg {
              width: 1rem;
              height: 1rem;
              color: var(--text-color-2);
            }
          }
        }

        .ask-button {
          background-color: var(--prime-color);
          color: white;
          padding: 0.5rem 1rem;
          border-radius: 6px;
          border: none;
          cursor: pointer;
          font-size: 1rem;
          transition: opacity 0.2s;

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }

    .results-box {
      background-color: var(--background-color-2);
      border-radius: 8px;
      padding: 1.5rem;
      margin-bottom: 2rem;
    }

    .recent-chats {
      .label {
        color: var(--text-color-2);
        margin-bottom: 1rem;
      }

      .chat-item {
        background-color: var(--background-color-2);
        border-radius: 8px;
        padding: 1rem;
        margin-bottom: 0.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .chat-name {
          color: var(--text-color-2);
        }

        .open-label {
          color: var(--text-color-3);
        }
      }
    }
  }
}

.streaming-analysis {
  background-color: var(--background-color-2);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}