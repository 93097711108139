.error-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.error-dialog-container {
  background-color: #222222;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  max-width: 500px;
  color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.error-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.error-dialog-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.error-dialog-close-button {
  background-color: #f44336;
  color: white;
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.error-dialog-message {
  margin-bottom: 24px;
  line-height: 1.5;
}

.error-dialog-actions {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.error-dialog-button {
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button {
  background-color: transparent;
  border: 1px solid #555555;
  color: white;
}

.cancel-button:hover {
  background-color: #333333;
}

.select-button {
  background-color: #f5923e;
  border: none;
  color: white;
}

.select-button:hover {
  background-color: #e67e22;
}

@media (max-width: 576px) {
  .error-dialog-actions {
    flex-direction: column;
  }

  .error-dialog-button {
    width: 100%;
  }
}