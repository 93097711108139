// PerformanceDashboard.scss

// Variables
$border-radius: 8px;

.performance-dashboard {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--text-color-1);
  padding: 24px;
  border-radius: $border-radius;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  // Main layout container
  .dashboard-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 24px;
    }
  }

  // Left panel containing text and reply
  .left-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    
    @media (min-width: 768px) {
      flex: 1;
    }
  }

  // Right panel containing charts
  .right-panel {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    max-height: 100%;
    
    @media (min-width: 768px) {
      flex: 1;
    }
  }

  // User query and profile section
  .user-query {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-shrink: 0;

    .avatar {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--prime-color);
      display: flex;
      align-items: center;
      justify-content: center;
      
      &-text {
        color: var(--text-color-1);
        font-weight: bold;
      }
    }

    .query-text {
      margin-left: 16px;
      
      p {
        color: var(--text-color-1);
        margin: 0;
      }
    }
  }

  // Placeholder text section
  .placeholder-text {
    background-color: var(--background-color-2);
    padding: 16px;
    border-radius: $border-radius;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
    flex: 1; // Take up available space

    .icon {
      margin-top: 4px;
      margin-right: 12px;
      color: var(--text-color-1);
    }

    p {
      color: var(--text-color-2);
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
    }
  }

  // Chart sections
  .chart-section {
    background-color: var(--background-color-2);
    padding: 16px;
    border-radius: $border-radius;
    margin-bottom: 24px;
    
    @media (min-width: 768px) {
      margin-bottom: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    h2 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 16px;
      text-align: center;
      color: var(--text-color-1);
    }

    // Bar chart
    .bar-chart {
      height: 256px;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      flex: 1;

      .bar-column {
        display: flex;
        flex-direction: column;
        align-items: center;

        .bar {
          width: 64px;
          background-color: var(--prime-color);
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          transition: height 0.3s ease;
          min-height: 4px;
        }

        .bar-label {
          margin-top: 8px;
          font-size: 12px;
          color: var(--text-color-1);
          text-align: center;
        }
      }
    }

    // Radar chart
    .radar-chart {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 256px;
      flex: 1;

      .radar-area {
        fill: var(--prime-color-light);
        stroke: var(--prime-color);
        stroke-width: 2;
      }

      .radar-point {
        fill: var(--prime-color);
      }

      .legend-color {
        fill: var(--prime-color);
      }

      svg {
        max-width: 100%;
        height: auto;
      }
    }
  }

  // Chat history container
  .chat-history {
    flex: 1;
    overflow-y: auto;
    padding-right: 16px;
    margin-bottom: 24px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--background-color-2);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--background-color-3);
      border-radius: 4px;
    }
  }

  // Analysis container
  .analysis-container {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    background-color: var(--background-color-2);
    padding: 16px;
    border-radius: $border-radius;

    .icon {
      color: var(--prime-color);
      flex-shrink: 0;
    }

    .analysis-content {
      flex: 1;

      .analysis-heading {
        color: var(--text-color-1);
        margin: 0 0 16px 0;
        font-size: 18px;
        font-weight: 500;
      }

      .analysis-paragraph {
        color: var(--text-color-1);
        margin: 0 0 16px 0;
        line-height: 1.6;
      }

      .analysis-list {
        color: var(--text-color-1);
        margin: 0 0 16px 0;
        padding-left: 24px;

        .analysis-list-item {
          margin-bottom: 8px;
        }
      }

      .analysis-bold {
        color: var(--text-color-1);
        font-weight: 600;
      }
    }
  }

  // Reply section
  .reply-section {
    background-color: var(--background-color-2);
    padding: 16px;
    border-radius: $border-radius;
    margin-top: auto;

    .input-container {
      display: flex;
      gap: 16px;
      align-items: center;

      .reply-input {
        flex: 1;
        background-color: var(--background-color-3);
        border: 1px solid var(--border);
        border-radius: 4px;
        padding: 12px;
        color: var(--text-color-1);
        font-size: 14px;
        font-family: inherit;

        &::placeholder {
          color: var(--text-color-3);
        }

        &:focus {
          outline: none;
          border-color: var(--prime-color);
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }

      .send-button {
        background-color: var(--prime-color);
        color: var(--text-color-1);
        border: none;
        border-radius: 4px;
        padding: 12px 24px;
        cursor: pointer;
        font-size: 14px;
        transition: opacity 0.2s;
        font-family: inherit;

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }

  // Footer text
  .footer-text {
    text-align: center;
    color: var(--text-color-2);
    font-size: 12px;
    margin-top: 8px;
    padding: 8px;
  }

  // Responsive improvements
  @media (max-width: 767px) {
    .dashboard-layout {
      .right-panel {
        margin-top: 24px;
      }
    }

    .chart-section {
      .bar-chart {
        .bar-column {
          .bar {
            width: 40px;
          }
        }
      }
    }
  }
}