.drag-drop {
    border: 1px solid var(--border);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;

    .document-uploader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        align-items: center;
        padding: 20px;

        .upload-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            align-items: center;


            .header {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }

            .hint {
                color: var(--gray-500);
                font-weight: 400;
                font-size: 14px;
                margin: 0;
            }

            .browse-btn {
                padding: 10px 10px 5px 10px;
                border: 1px solid var(--border);
                border-radius: 10px;
                cursor: pointer;
            }
        }

        .file-list {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .file-item {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border: 1px solid var(--border);
                padding: 0 10px;
                border-radius: 20px;

                .file-actions {
                    cursor: pointer;
                }
            }
        }

    }
}