.common-date-picker-container {
  .common-date-picker-pre-label {
    margin: 0;
    padding: 0 0 5px 5px;
    font-size: 14px;
    text-align: left;
  }

  .common-date-picker {
    width: 100%;

    .Mui-disabled {
      &::before {
        border-bottom-style: solid !important;
      }
    }

    .MuiInputBase-colorPrimary {
      border-radius: 10px;
      border-color: var(--field-border);
    }

    .MuiInputBase-root {
      fieldset {
        border-color: var(--field-border) !important;
      }

      &:hover {
        fieldset {
          border-color: var(--field-border) !important;
        }
      }

      input {
        height: 10px !important;
      }
    }

    .Mui-error {
      fieldset {
        border-color: #d32f2f !important;
      }

      &:hover {
        fieldset {
          border-color: #d32f2f !important;
        }
      }
    }

    .error-msg {
      color: red;
    }
  }

  .error-date-picker {
    .MuiInputBase-root {
      fieldset {
        border-color: #d32f2f !important;
      }

      &:hover {
        fieldset {
          border-color: #d32f2f !important;
        }
      }
    }
  }
}