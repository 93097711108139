.main-settings-container {
    .question {
        margin: 5px 0;
        font-weight: 500;
    }

    .answer {
        margin-top: 0;
        color: var(--secondary-text);
        margin-bottom: 10px;
    }

    .exam-date {
        max-width: 250px;
    }

    .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .text-field {
            width: 100px;
            margin: auto;

            .mark-text {
                .MuiInputBase-root {
                    font-weight: 800;
                    font-size: 22px;
                    background-color: #F5F5F5;
                    color: #171717;

                    .MuiInputBase-input {
                        text-align: center;
                    }
                }
            }
        }
    }

    .btn-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        .save-btn {
            width: clamp(50px, 100%, 150px);
        }
    }



    .question-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text-container {

            .question {
                margin: 5px 0;
                font-weight: 500;
            }

            .answer {
                margin-top: 0;
                color: var(--secondary-text);
                margin-bottom: 10px;
            }
        }
    }


    .top-box2 {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .question-container2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
    }

    .text-container2 {
        .question {
            margin: 5px 0;
            font-weight: 500;
        }

        .answer {
            margin-top: 0;
            color: var(--secondary-text);
            margin-bottom: 10px;
        }
    }

    .sub-settings2 {
        margin-left: 16px;
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-left: 1px solid #eee;
    }

}