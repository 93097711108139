.performance-chart {
    padding: 20px;
    background-color: var(--background-color-2);
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    // width: 100%;
    // width: fit-content;
    // max-width: 600px;
    // width: 100%;
    // height: 100%;

    .time-allocation-title {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }

    .canvas-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: clamp(100px, 100%, 400px) !important;

        canvas {
            width: 100% !important;
        }
    }
}