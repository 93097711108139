.app-toggle {
    width: 42px;
    height: 26px;
    padding: 0;


    .MuiSwitch-thumb {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        background-color: currentColor;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        position: relative;
        
        .switch-loader {
            color: var(--prime-color);
            position: absolute;
            top: 15%;
            left: 15%;
        }
    }

    .switch-icon {
    }

}