.notification-trigger {
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--icons-stroke);
}

.notification-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--prime-color);
    color: var(--text-color-2);
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 12px;
}

.notifications-popover {
    width: clamp(100px, 100%, 800px);
    max-height: 400px;
    overflow-y: auto;
    background-color: var(--background-color-3);
    border: 1px solid var(--border);
    border-radius: var(--radius-md);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .not-header {
        margin: 0;
        padding: 10px;
        font-size: 16px;
    }

    .notifications-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .clear-all {
        padding: 0 20px;
    }
}

.notifications-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    padding: 10px;

    .no-content {
        color: var(--placeholder);
    }
}

.notification-item {
    padding: var(--spacing-lg);
    border-bottom: 1px solid var(--border);
    width: 100%;
}

.notification-header {
    display: grid;
    grid-template-columns: 50px minmax(0, 600px);
    gap: 10px;
    margin-bottom: 5px;
    color: var(--text-color-1);


    h3 {
        font-size: var(--Font-size-text-md);
        margin: 0;
    }

    span {
        font-size: 12px;
        color: var(--secondary-text);
    }

    .right-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        .notification-content {
            background-color: var(--background-color-2);
            padding: 10px;
            border-radius: var(--radius-md);

            .text-block {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 5px;

                .title-content {
                    padding-left: 20px;
                    display: flex;
                    justify-content: space-between;

                    h4 {
                        margin: 5px 0;
                        color: var(--text-color-1);
                    }

                    .question-count {
                        font-size: 12px;
                        color: var(--secondary-text);
                        padding: 5px;
                        height: fit-content;
                        background-color: var(--question-bg);
                        border-radius: 5px;

                        .count {
                            color: var(--text-color-1);
                            font-weight: 500;
                        }
                    }
                }

                .subject-category {
                    font-size: 12px;
                    color: var(--prime-color);
                    background-color: var(--profile-bg);
                    padding: 10px;
                    border-radius: 5px;
                    margin-left: 20px;
                    width: fit-content;
                }

                p {
                    padding-left: 20px;
                    margin: 5px 0;
                    font-size: 14px;
                    width: clamp(200px, 100%, 600px);
                    color: var(--secondary-text);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 3px;
                    height: 100%;
                    display: block;
                    background-color: var(--prime-color);
                }
            }
        }
    }
}

.MuiPaper-elevation {
    width: fit-content;
}