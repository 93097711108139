.header-layout {
    display: flex;
    justify-content: space-between;
    padding: 35px 35px 0 35px;
    align-items: center;

    .icon-container {
        display: flex;
        align-items: center;
        gap: 10px;

        .theme-switch {
            cursor: pointer;

            .iconfit {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .logout-icon {
            width: 22px;
            height: 22px;
            cursor: pointer;
            margin-bottom: 2px;

            path {
                stroke: var(--icons-stroke);
            }
        }
    }
}

.all-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;

    .authenticated {
        height: 100%;
        border-radius: 10px;
        background-color: var(--background-color-2) !important;
        background-image: var(--bg-img);
        background-size: cover;
    }

    .login {
        height: 100%;
    }
}