@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

.app-calendar-container {
  width: 100%;
  height: 100%;

  .app-calendar {
    color: #969696;
    min-height: 600px;
    height: 100%;

    * {
      border-color: var(--border) !important;
    }

    .rbc-today {
      color: var(--text-color-1);
      font-weight: 500;
      background-color: var(--calendar-today-bg);
    }

    .rbc-off-range-bg {
      background-color: var(--calendar-off-range-bg);

    }

    .rbc-event {
      padding: 0;
      background-color: transparent;
    }

    .custom-toolbar {
      gap: 10px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .btn-group {
        background-color: var(--special-bg);
        padding: 5px;
        border-radius: 5px;
        width: fit-content;
        display: flex;
        align-items: stretch;

        &:first-of-type {
          // Add styles for the first button group here
          display: flex;
          gap: 5px;

          .arrow {
            color: var(--text-color-1) !important;
            width: fit-content;
          }

          button {
            color: var(--text-color-1) !important;
            background-color: var(--calendar-active-bg) !important;
            border-radius: 5px;
            font-size: 16px;
            padding: 5px 10px;
          }
        }

        button {
          border-radius: 5px;
          font-size: 16px;
          color: #969696;
          font-weight: 600;
          background-color: var(--special-bg);
          cursor: pointer;
          border: none;
          box-shadow: none;
          padding: 10px 15px;
          width: 7rem;
          flex: 1;
          min-width: fit-content;

          &.rbc-active {
            color: var(--text-color-1);
            background-color: var(--calendar-active-bg) !important;
            border-radius: 5px;
          }
        }
      }
    }

    .rbc-show-more {
      background-color: transparent;
    }
  }
}

.card-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  background-color: #f5f5f5;

  .card-title {
    text-align: center;
    margin-bottom: 10px;
  }
}


@media screen and (max-width: 1000px) {
  .app-calendar {
    max-width: 550;
  }
}

@media screen and (max-width: 900px) {
  .app-calendar {
    max-width: 450px;
  }
}

@media screen and (max-width: 800px) {
  .app-calendar {
    max-width: 350px;
  }
}

@media screen and (max-width: 700px) {
  .app-calendar {
    max-width: 520px;

    .custom-toolbar {
      justify-content: center !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .app-calendar {
    max-width: 420px;
  }
}

@media screen and (max-width: 500px) {
  .app-calendar {
    max-width: 320px;
  }
}

@media screen and (max-width: 400px) {
  .app-calendar {
    max-width: 300px;
  }
}

@media screen and (max-width: 300px) {
  .app-calendar {
    max-width: 200px;
  }
}

@media screen and (max-width: 100px) {
  .app-calendar {
    max-width: 80px;
  }
}

@media screen and (max-width: 200px) {
  .app-calendar {
    max-width: 150px;
  }
}