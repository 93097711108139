.common-button {
   color: #fff !important;
   text-transform: none !important;
   background-color: #FF7F50 !important;
   width: 100%;

   :hover {
      background-color: #FF6347 !important;
   }
}

.button-cancel {
   color: red !important;
   background-color: #fff !important;
   border: none !important;
}

.button-disabled {
   color: #fff !important;
   text-transform: none !important;
   background-color: #47403e !important;
   width: 100%;

   :hover {
      background-color: #FF6347 !important;
   }
}

.button-back {
   color: var(--gray-500) !important;
   background-color: transparent !important;
   border: none !important;
   box-shadow: none !important;
}

.button-analyse {
   color: var(--background-color) !important;
   width: fit-content !important;
   background-color: var(--text-color-1) !important;
   border: none !important;
   margin-top: 30px !important;
}

.button-custom-outlined {
   color: var(--prime-color) !important;
   width: fit-content !important;
   border-color: var(--prime-color) !important;
   background-color: transparent !important;
}