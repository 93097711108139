.login-container {
    height: calc(100vh - 150px);
    height: fit-content;
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;

    .box-container {
        background-color: var(--background-color);
        color: var(--text-color-1);
    }

    .reset-container {
        min-width: 300px;

        .sign-in-header {
            color: var(--text-color-1);
        }
    }
}