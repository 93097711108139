.overview {
    background-color: var(--background-color);
    border-radius: 32px;
    height: 500px;

    .bottom-section {
        display: flex;
        margin-top: 36px;

        .vertical-line {
            border-left: 1px solid #BEBEBE;
            height: 210px;
            margin: 0 40px;
        }

        .bottom-section-left {
            display: flex;
            flex-direction: column;
            // padding-left: 28px;

            .bottom-section-left-header {
                display: flex;
                max-height: 44px;

                .bottom-section-left-header-label {
                    font-family: 'Figtree';
                    font-weight: bolder;
                    font-size: 22px;
                    color: var(--text-color-1)
                }


                .bottom-section-left-header-seeall {
                    display: flex;
                    flex-direction: row-reverse;
                    font-family: 'Figtree';
                    color: var(--text-color-1)
                }
            }

            .bottom-section-left-values {
                display: flex;

                .bottom-section-left-value {
                    display: flex;
                    flex-direction: column;
                    background-color: var(--background-color);
                    border-radius: 32px;
                    margin-right: 18px;

                    .bottom-section-left-value-top {
                        display: flex;
                        max-height: 82px;

                        .bottom-section-left-value-top-left {
                            height: 48px;
                            width: 48px;
                            max-height: 48px;
                            max-width: 48px;
                            margin: 18px;
                            background-color: var(--color-1);
                            border-radius: 8px;
                        }

                        .bottom-section-left-value-top-right {
                            display: flex;
                            margin-right: 18px;
                            max-height: 30px;
                            margin-top: 18px;
                            flex-direction: row-reverse;
                            font-family: "Figtree";
                            font-weight: 100;
                            font-size: 16px;
                            color: var(--text-color-1)
                        }
                    }

                    .bottom-section-left-value-mid-1 {
                        max-height: 20px;
                        font-family: 'Figtree';
                        font-weight: bolder;
                        font-size: 16px;
                        margin-left: 18px;
                        color: var(--text-color-1)
                    }

                    .bottom-section-left-value-mid-2 {
                        max-height: 30px;
                        font-family: "Figtree";
                        font-weight: 100;
                        font-size: 16px;
                        margin-left: 18px;
                        color: var(--text-color-1)
                    }

                    .bottom-section-left-value-bottom {
                        max-height: 30px;
                        font-family: 'Figtree';
                        font-weight: bolder;
                        font-size: 16px;
                        margin-left: 18px;
                        color: var(--color-1);
                    }
                }
            }
        }

        .bottom-section-right {
            display: flex;
            flex-direction: column;

            .bottom-section-right-header {
                display: flex;


                .bottom-section-right-header-label {
                    color: var(--text-color-1);
                    font-family: 'Figtree';
                    font-weight: bolder;
                    font-size: 22px;
                }


                .bottom-section-right-header-seeall {
                    display: flex;
                    flex-direction: row-reverse;
                    padding-right: 8px;
                    font-family: 'Figtree';
                    color: var(--text-color-1)
                }
            }

            .bottom-section-right-value {
                display: flex;

                .bottom-section-right-value-left {
                    height: 40px;
                    width: 40px;
                    max-height: 40px;
                    max-width: 40px;
                    margin-right: 18px;
                    background-color: var(--color-1);
                    border-radius: 8px;
                }

                .bottom-section-right-value-right {
                    display: flex;
                    min-width: 90%;
                    flex-direction: column;

                    .bottom-section-right-value-right-top {
                        display: flex;
                        max-height: 20px;

                        .bottom-section-right-value-right-top-left {
                            display: flex;
                            max-height: 16px;
                            font-family: 'Figtree';
                            font-weight: bolder;
                            font-size: 16px;
                            color: var(--text-color-1);
                            max-width: 30vw;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .bottom-section-right-value-right-top-right {
                            display: flex;
                            flex-direction: row-reverse;
                            max-height: 20px;
                            font-family: 'Figtree';
                            font-weight: bolder;
                            font-size: 16px;
                            margin-left: 18px;
                            color: var(--color-1);
                        }
                    }

                    .bottom-section-right-value-right-bottom {
                        display: flex;
                        color: var(--text-color-1)
                    }
                }
            }
        }
    }
}

.line-chart-row {
    display: flex;

    .line-chart {
        margin-left: 34px !important;
        margin-top: 12px !important;
        background-color: var(--color-2);
        border-radius: 24px;
        padding: 16px;
        height: 80px;

        .line-chart-name {
            font-family: 'Figtree';
            font-weight: bold;
            font-size: 14px;
            color: var(--text-color-1)
        }
    }
}

.main-chart-row {
    display: flex;

    .main-chart-row-header {
        display: flex;
        padding-top: 26px;

        .main-chart-row-header-value {
            font-family: revert;
            font-weight: bolder;
            font-size: 32px;
            color: var(--color-1);
        }
    }

    .line-chart {
        margin-top: 12px !important;
        padding: 16px;
        height: 220px;
        display: flex;
        // height: 100%;

    }

    .pie-chart {
        // margin-top: 12px !important;
        height: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        .pie-chart-label {
            margin-top: 14px !important;
            font-family: 'Figtree';
            font-weight: bold;
            font-size: 16px;
            color: var(--text-color-1);
        }

        .pie-chart-legends {
            color: var(--color-1);
        }

        .recharts-wrapper:focus,
        .recharts-wrapper *:focus {
            outline: none;
        }
    }

    .main-chart-legends {
        padding-top: 78px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .main-chart-legends-header {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            .main-chart-legends-header-label {
                font-family: 'Figtree';
                font-weight: 500;
                color: var(--text-color-2);
                font-size: 16px;
                background-color: var(--color-1);
                border-radius: 4px;
                display: flex;
                width: 80%;
                justify-content: center;
                align-items: center;
                height: 24px;
            }
        }

        .main-chart-legends-data {
            display: flex;
            width: 100%;
            padding-left: 12px;
            justify-content: center;
            align-content: center;
            align-items: center;

            .main-chart-legends-data-color-pallet {
                max-width: 12px;
                height: 24px;
                border-radius: 4px;
                display: flex;
            }

            .main-chart-legends-data-value {
                display: flex;
                color: var(--text-color-1);
                padding-left: 18px;
                align-content: center;
                align-items: center;

            }
        }
    }
}

.locked-container {
    position: relative;
}

.locked-content {
    filter: blur(5px);
    pointer-events: none;
    /* Disable interactions */
}

.lock-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    /* Ensure the lock icon is above the blurred content */
    pointer-events: none;
    font-size: 48px;
    color: rgba(0, 0, 0, 0.7);
    /* Disable interactions */
}

.normal-content {
    pointer-events: auto;
    /* Enable interactions when logged in */
    filter: none;
    /* Ensure no blur when logged in */
}

.not-visible {
    opacity: 0;
}

@keyframes reveal {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.visible {
    // transition: 'transform 5s ease-in';
    // transform: 'translateX(0)';
    animation-name: reveal;
    animation-duration: 0.8s;
}