.error-logs-analyzed {
    display: flex;
    gap: 20px;
    align-items: center;
    background-color: var(--background-color-2);
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    height: 100%;
    justify-content: space-between;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    .text-content {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
            color: var(--icons-stroke);
            font-size: 12px;
            margin: 0;
        }

        .value {
            color: var(--text-color-1);
            margin: 0;
            font-size: 18px;
            font-weight: 500;
        }
    }

    .svg-container {
        padding: 10px 10px 8px 10px;
        background-color: var(--profile-bg);
        border-radius: 10px;
    }
}