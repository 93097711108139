.bottom-bar-container {
    background-color: var(--background-color-2);
    border-bottom: 1px solid var(--border);
    color: var(--text-color-1);
    height: 48px;
    width: calc(100vw - 280px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    font-size: small;
}