.forgot-password-container {
    .sign-in-header {
        font-size: 20px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: -0.02em;
        color: var(--text-color-1);
        text-align: left;
    }

    .reset-hint {
        color: var(--secondary-text);
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
    }
    .hint {
        color: var(--prime-color);
        font-size: 16px;
        margin: 0;
        margin-top: 20px;
    }

    .text-field-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .login-btn {
        margin-top: 20px !important;
    }

    .bottom-text {
        margin-top: 10px !important;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
        align-items: baseline;
        width: 100%;

        .forgot-pass {
            font-size: 12px;
        }
    }
}