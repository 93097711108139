.tags-dialog-tag {
  display: 'inline-block';
  padding: '5px 12px';
  margin: '3px';
  border-radius: '20px';
  background-color: var(--tag);
  color: var(--text-color-3);
  font-size: '14px';
  border: '1px solid #ddd';
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tags-dialog-tag-selected {
  display: 'inline-block';
  padding: '5px 12px';
  margin: '3px';
  border-radius: '20px';
  color: var(--text-color-3);
  font-size: '14px';
  border: '1px solid #ddd';
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}