.onboarding-container {
    height: calc(100vh - 180px) !important;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--text-color-1);

    .text {
        color: var(--text-color-1);
        margin: 20px 10px;
    }

    // .onboarding-form {
    //     width: 100%;
    //     padding: 25px;
    // }
}

@media screen and (max-width: 600px) {
    .onboarding-form {
        width: 100%;
        padding: 30px;

        .content-container {
            max-width: 100% !important;
            padding: 0;
        }
    }
}