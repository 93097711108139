.tags-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.tags-dialog-container {
  background-color: var(--color-2);
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tags-dialog-header {
  display: flex;
  justify-content: flex-end;
}

.tags-dialog-close-button {
  background-color: #f44336;
  color: white;
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.tags-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tags-dialog-tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.tags-dialog-tag {
  background-color: var(--tag);
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tags-dialog-tag-selected {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tags-dialog-item {
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  margin-top: 10px;
}

.tags-dialog-item-number {
  font-weight: bold;
  margin-right: 10px;
}

.tags-dialog-item-text {
  font-weight: bold;
}

.tags-dialog-cursor {
  padding: 0 20px;
  color: #666;
  font-family: monospace;
}

.tags-dialog-notes-container {
  padding: 0 20px;
  margin-top: 10px;
}

.tags-dialog-notes {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  resize: vertical;
  min-height: 80px;
}

.tags-dialog-notes:focus {
  outline: none;
  border-color: #f5923e;
  box-shadow: 0 0 0 2px rgba(245, 146, 62, 0.2);
}

.tags-dialog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 10px;
}

.tags-dialog-button {
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.reset-button {
  background-color: transparent;
  border: 1px solid #dddddd;
  color: #666666;
}

.reset-button:hover {
  background-color: #f5f5f5;
}

.save-button {
  background-color: #f5923e;
  border: none;
  color: white;
}

.save-button:hover {
  background-color: #e67e22;
}

@media (max-width: 576px) {
  .tags-dialog-actions {
    flex-direction: column;
  }

  .tags-dialog-button {
    width: 100%;
  }
}