.rating-component-container {
    width: 100%;
    min-width: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;

    .MuiSlider-marked {
        color: #FAFAFA;
        display: flex;
        height: 22px;
        border-radius: 0;
        padding: 0;
        position: relative;

        .slider-rail {
            border-radius: 5px 5px 0 0;
            padding: -52px;
            opacity: 1;
            width: calc(100% + 2.1px);
            margin-left: -1px;
            content: "";
            position: absolute;
            height: 100%;
        }
        .range-mark-label{
            border-radius: 8px 8px 0 0;
            background-color: #FAFAFA;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .each-mark {
            position: absolute;
            // color: #6B7280;
            top: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            transform: translateX(-50%);

            .mui-mark-label {
                color: var(--text-color-1);
                flex: 1;
            }

            .top-fit {
                content: "";
                width: 10px;
                height: 6px;
                background-color: var(--background-color);
                position: absolute;
                top: -22px;
                border: none;


                &:before {
                    content: "";
                    width: 65%;
                    border-radius: 5px 0 0 0;
                    right: -1px;
                    height: 118%;
                    background-color: #FAFAFA;
                    position: absolute;
                }

                &:after {
                    content: "";
                    width: 65%;
                    border-radius: 0 5px 0 0;
                    left: -1px;
                    // bottom: -1.5px;
                    height: 118%;
                    background-color: #FAFAFA;
                    position: absolute;
                }
            }

            .MuiSlider-mark {
                width: 2px;
                height: 2px;
                border-radius: 1px;
                background-color: #E5E5E5;
                height: 15px;
            }
        }


        .MuiSlider-thumb {
            color: var(--prime-color);
            margin-top: -27px;
            left: 62.5%;

            &::before {
                width: 3px;
                height: 45px;
                background-color: var(--prime-color);
                border-radius: 1px;
                top: 95%;
            }

        }
    }
}

.slider-track {
    // background-color: var(--prime-color) !important;
    height: 100%;
    color: white;
    content: "";
    position: absolute;
    border-radius: 5px 0 0 0;
    opacity: 1;
    width: calc(100% + 2.1px);
    margin-left: -1px;
    z-index: 99;
    opacity: 0.5;
    overflow: hidden;
    display: flex;
}