.content-container {
    width: fit-content;
    height: 70dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    max-width: 60vw;

    .top-area {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .question-container {
            .question {
                margin: 0;
                font-weight: 600;
                font-size: 22px;
            }

            .hint {
                // margin: 0;
                color: var(--gray-500);
                font-weight: 400;
                font-size: 16px;
            }

            .old-status {
                display: flex;
                align-items: center;
            }
        }

        .input-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .text-field {
                width: 100px;
                margin: auto;

                .mark-text {
                    .MuiInputBase-root {
                        font-weight: 800;
                        font-size: 22px;

                        .MuiInputBase-input {
                            text-align: center;
                        }
                    }
                }
            }
            .motivation {
                width: 0px;
                height: fit-content;
                white-space: nowrap;
                overflow: hidden;
                transition: all 0.5s;

                &.active {
                    width: 100%;
                    height: fit-content;
                }

                &.error-cls {
                    color: red !important;
                }
            }
        }

        .onboarding-date {
            max-width: 295px;
        }
    }

    .bottom-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .continue-button {
            width: fit-content;
            background-color: var(--prime-color);
        }
    }
}