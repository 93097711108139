.sidemenu {
    display: flex;
    justify-content: center;

    .side-menu {
        width: 100%;

        .sidemenu-items {
            margin: 15px 0;
            display: flex;
            gap: 10px;
            flex-direction: column;

            .navigation-link {
                text-decoration: none;
                color: var(--text-color-1);

                .sidemenu-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;
                    height: 40px;
                    cursor: pointer;

                    h1 {
                        margin: 0;
                        font-size: 14px;
                        font-weight:500;
                        color: var(--menu-item-color);
                    }
                }

                .sidemenu-item-active {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
                    height: 40px;
                    cursor: pointer;

                    h1 {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                    }

                    .sub-menu-left {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 20px;
                        height: 40px;
                        cursor: pointer;

                        h1 {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }

                &.active {
                    .sidemenu-item-active {
                        // background: var(--color-1);
                        color: var(--active-color);
                        border-radius: 8px;
                    }

                }
            }
        }
    }
}

.bulk-upload {
    .custom-file-upload {
        input[type="file"] {
            display: none;
        }
    }
}

.more-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 232px;
}

.profile-image {
    width: 46px;
    height: 46px;
    border-radius: 100%;
}

.profile-image-dark {
    width: 46px;
    height: 46px;
    border-radius: 100%;
    filter: invert(100%) brightness(2);
}

.lock-icon-side-menu {
    width: 46px;
    height: 46px;
    border-radius: 100%;
}

.lock-icon-side-menu-dark {
    width: 46px;
    height: 46px;
    border-radius: 100%;
    filter: invert(100%) brightness(2);
}

.locked-sidemenu {
    cursor: not-allowed !important;
}

.menu-item-wrapper {
    position: absolute;
    right: 0;
}

.login-menu .MuiMenu-paper {
    top: 80px !important;
    right: 80px !important;
    left: auto !important;
}

.brand-logo {
    height: 54px
}

.brand-logo-text-1 {
    color: var(--text-color-1);
}

.brand-logo-text-2 {
    color: var(--title-color);
}

@keyframes vibrate {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-2px, 2px);
    }

    40% {
        transform: translate(-2px, -2px);
    }

    60% {
        transform: translate(2px, 2px);
    }

    80% {
        transform: translate(2px, -2px);
    }

    100% {
        transform: translate(0);
    }
}

.vibrate {
    animation: vibrate 0.3s linear;
}