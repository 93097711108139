.top-bar-container {
    background-color: var(--background-color-2);
    border-bottom: 1px solid var(--border);
    color: var(--text-color-1);
    height: 72px;
    width: calc(100vw - 280px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .left-content {
        font-weight: 500;
        font-size: 16px;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .right-content {
        display: flex;
        gap: 10px;

        .app-avatar {
            cursor: pointer;
            font-weight: 600;
        }

    }

}

.user-popover {
    background-color: var(--background-color);
    min-width: 20vw;
    display: flex;
    flex-direction: column;

    .first-set {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid var(--border);


        .inner-header {
            width: 100%;
            font-weight: 500;
            margin: 0;
        }

        .exp-content {
            color: var(--gray-500);
            font-weight: 400;
            font-size: 14px;
            margin: 0;
        }
    }

    .second-set {
        padding: 10px;

        .each-set {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;

            .title {
                color: var(--same-text);
                margin: 0;
            }

            &.colored {
                background-color: var(--profile-bg);

                .title {
                    color: var(--prime-color);
                }
            }
        }
    }
}

.pointer-div {
    cursor: pointer;
    position: relative;

    &.available {
        &::after {
            content: "";
            background-color: red;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 5px;
            right: 5px;
            border-radius: 50%;
        }

    }
}

.iconfit {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 700px) {

    .top-bar-container {
        width: 100vw;
    }
}