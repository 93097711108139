.exam-countdown {
  background-color: var(--background-color-2);
  color: var(--text-color-1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  // width: 100%;
  height: 100%;

  .exam-date {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
      color: var(--text-color-1);
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 10px;
    }

    .common-date-picker-container {
      margin-top: 10px;
      margin: auto;
      width: 100%;
    }

    p {
      color: var(--icons-stroke);
      font-size: 12px;
      margin: 0;
    }
  }

  .countdown-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .circular-chart {
      width: clamp(150px, 100%, 200px);
      height: clamp(150px, 100%, 200px);
      // height: 150px;
    }

    .circle-bg {
      fill: none;
      stroke: var(--profile-bg);
      stroke-width: 3;
    }

    .circle {
      fill: none;
      stroke: var(--prime-color);
      stroke-width: 3;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
    }

    .percentage {
      fill: var(--text-color-1);
      font-size: 10px;
      text-anchor: middle;
      font-weight: bold;
    }

    .days-text {
      fill: var(--text-color-1);
      font-size: 3px;
      text-anchor: middle;
      opacity: 0.7;
    }
  }
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}