.accuracy-radar-chart {
  padding: 20px;
  background-color: var(--background-color-2);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &.dark-mode {
    background-color: #1E1E1E;
  }

  &.light-mode {
    background-color: #FFFFFF;
  }

  .time-allocation-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .canvas-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    canvas {
      width: clamp(300px, 100%, 600px) !important;
      height: clamp(300px, 100%, 600px) !important;
      //   height: fit-content !important;
    }
  }
}