.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background: var(0, 0, 0, 0.5) !important;
  border-radius: 12px;
  padding: 20px;
  width: 90vw;
  max-width: 1200px;
  max-height: 90vh;
  position: relative;
  animation: dialogSlideIn 0.3s ease-out;
}

.plans-container {
  padding: 20px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plans-stack {
  position: relative;
  width: 300px;
  height: 400px;
  margin: 40px auto;
  perspective: 1000px;
}

.plan-card {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--analyze-color, #FFF) !important;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-out;
  cursor: pointer;
  backface-visibility: hidden;
  transform-origin: center center -20px;
  display: flex;
  flex-direction: column;
}

.plan-card {
  --offset: calc(20px * abs(var(--index) - var(--active-index)));
  --offset2: calc(100px * abs(var(--index) - var(--active-index)));
  --rotate: calc(5deg * abs(var(--index) - var(--active-index)));
  --scale: calc(1 - (0.05 * abs(var(--index) - var(--active-index))));

  // transform: translateZ(calc(var(--offset) * -1)) translateX(calc(var(--offset2) * -1)) rotateX(var(--rotate)) scale(var(--scale));
  opacity: calc(1 - (0.2 * (var(--index) - var(--active-index))));
  // z-index: calc(var(--total-cards) - abs(var(--index) - var(--active-index)));
}

.plan-card.active {
  cursor: default;
  transform: translateZ(0) rotateX(0) scale(1);
  opacity: 1;
}

.plan-name {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #2c3e50;
}

.plan-description {
  color: #666;
  // margin-bottom: 15px;
  flex-grow: 1;
}

.plan-price {
  font-size: 1.8em;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 5px;
}

.plan-tax {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
}

.plan-total {
  font-size: 1.2em;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.plan-period {
  color: #666;
  margin-bottom: 20px;
}

.subscribe-button {
  background-color: var(--title-color, #FFF) !important;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.2s;
  margin-top: auto;
}

.subscribe-button:hover {
  background-color: #2980b9;
}

.navigation-controls {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.nav-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2em;
  transition: all 0.2s;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.nav-button:not(:disabled):hover {
  background-color: #2980b9;
  transform: scale(1.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
  z-index: 2;
}

.loading,
.error {
  text-align: center;
  padding: 40px;
  font-size: 1.2em;
}

.error {
  color: #e74c3c;
}

@keyframes dialogSlideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}