.loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;

    .logo-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        height: 250px;
        width: 250px;
        gap: 20px;
        justify-content: flex-end;
        align-items: center;

        svg {
            rect {
                stroke: var(--text-color-1);
            }
            ellipse {
                stroke: var(--text-color-1);
            }
            path {
                stroke: var(--text-color-1);
            }
        }

        .progress-div {
            width: 100%;

            .MuiLinearProgress-root {
                background-color: var(--field-border);
                border-radius: 5px;
            }

            .MuiLinearProgress-barColorPrimary {
                border-radius: 5px;
                background-color: var(--prime-color) !important;
            }
        }
    }
}