:root {
  --background-color: #FFFFFF;
  --background-color-2: #FFFFFF;
  --background-color-3: #FAFAFA;
  --background-color-4: #FAFAFA;
  --text-color-1: #252525;
  --text-color-2: #FFFFFF;
  --text-color-3: #252525;
  --title-color: #F66A2E;
  --color-1: #125167;
  --color-2: #FDFBF9;
  --color-3: #252525;
  --color-4: 253, 251, 249;
  --active-color: #FCAC63;
  --invert-percentage: 0%;
  --Font-family-font-family-body: sans-serif;
  --Font-size-text-md: 16px;
  --Line-height-text-md: 24px;
  --spacing-xl: 16px;
  --spacing-lg: 12px;
  --radius-md: 8px;
  --gray-300: #D1D5DB;
  --gray-200: #E5E7EB;
  --gray-500: #6B7280;
  --gray-900: #111827;
  --Colors-Background-bg-primary: #FFF;
  --colors-text-secondary-700: #344054;
  --border: #E4E7EC;
  --bg-img: url("./assets/light/gridBG.svg");
  --analyze-color: #D4D4D4;
  --analyze-color-bg: #F2F4F7;
  --prime-color: #EF884A;
  --profile-bg: #FFF7ED;
  --same-text: #404040;
  --calendar-today-bg: #eaf6ff;
  --calendar-off-range-bg: #dddddd;
  --placeholder: #667085;
  --tag: #7b7b7b;
  // Add other variables as needed

  --field-border: #E5E7EB;
  --special-bg: #F5F5F5;
  --icons-stroke: #4B5563;
  --calendar-active-bg: #FFFFFF;
  --secondary-text: #6B7280;
  --prelabel-color: #344054;
  --menu-item-color: #A3A3A3;
  --question-bg: #F9FAFB;
  --scrollbar: #D9D9D9;
}

.dark-mode {
  --background-color: #121212;
  --background-color-2: #232323;
  --background-color-3: #121212;
  --background-color-4: #232323;
  --text-color-1: #FFFFFF;
  --text-color-2: #020C1B;
  --text-color-3: #020C1B;
  --title-color: #F66A2E;
  --color-1: #FCAC63;
  --color-2: #252525;
  --color-3: #FCAC63;
  --color-4: 37, 37, 37;
  --active-color: #FCAC63;
  --invert-percentage: 100%;
  --bg-img: url("./assets/dark/gridBG.svg");
  --border: #404040;
  --analyze-color: #98A2B3;
  --analyze-color-bg: #737373;
  --prime-color: #EF884A;
  --profile-bg: #383838;
  --same-text: #404040;
  --calendar-today-bg: #5e5e5e;
  --calendar-off-range-bg: #b6b6b6;
  --gray-500: #E5E7EB;
  --placeholder: #6B7280;
  --tag: #7b7b7b;
  // Add other variables for dark mode as needed

  --field-border: #6B7280;
  --special-bg: #121212;
  --icons-stroke: #D4D4D4;
  --calendar-active-bg: #383838;
  --prelabel-color: #F3F4F6;
  --secondary-text: #D4D4D4;
  --menu-item-color: #A3A3A3;
  --question-bg: #383838;
  --scrollbar: #383838;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);

}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  opacity: 1.5;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html * {
  box-sizing: border-box;
}