.welcome-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .question-container {
        .question {
            margin: 0;
            font-weight: 600;
            font-size: 22px;
        }

        .hint {
            // margin: 0;
            color: var(--gray-500);
            font-weight: 400;
            font-size: 16px;
        }
    }

    .name-container {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 20px;
        background-color: var(--background-color-3);
        border-radius: 10px;

        .input-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;

            .greet {
                color: var(--gray-500);
                font-weight: 400;
                font-size: 14px;
                margin: 0;
                margin-left: 5px;
            }

            .input-name {
                height: 100%;
                font-size: 16px;
                font-weight: 500;
                color: var(--text-color-1);
                border: none;
                min-height: 30px;
                width: 100%;
                padding: 5px;

                &:focus-visible {
                    border: none !important;
                    outline: none;
                }
            }
        }
    }
    .welcome-btn{
        width: fit-content;
    }
}