.main {
    display: grid;
    grid-template-columns: 280px 1fr;
    background-color: var(--background-color-2);

    .side-menus {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 100%;
        height: 100dvh;
        gap: 10px;
        border-right: 1px solid var(--border);

        .logo {
            .brand-log0 {
                height: 30px;
            }
        }

        .mobile-menu-toggle {
            display: none;
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 1000;
            background: none;
            border: none;
            color: var(--text-color-1);
            cursor: pointer;
            padding: 8px;
        }
    }

    .right-container {
        .content {
            background-color: var(--background-color-3);
            color: var(--text-color-1);
            height: calc(100vh - 72px);
            width: calc(100vw - 280px);
            overflow: auto;
            display: grid;
            position: relative;
            // border-radius: 32px;
        }
    }

    &.small-screen {
        grid-template-columns: 1fr;

        .side-menus {
            position: fixed;
            left: -280px;
            top: 0;
            z-index: 1000;
            transition: left 0.3s ease;
            background-color: var(--background-color-2);

            &.open {
                left: 0;
            }

            .mobile-menu-toggle {
                display: block;
            }
        }

        .right-container {
            .content {
                width: 100vw;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .main {
        grid-template-columns: 1fr;

        .side-menus {
            width: 280px;
        }

        .content {
            width: 100vw !important;
        }
    }
}