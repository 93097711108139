.time-allocation-chart {
    padding: 20px;
    background-color: var(--background-color-2);
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;

    .time-allocation-title {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }

    .canvas-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;

        canvas {
            width: 100% !important;
            height: auto !important;
            max-height: 300px;
            min-height: 200px;
        }
    }
}

// @media (prefers-color-scheme: dark) {
//     .time-management-chart {
//         background-color: #1e1e1e;
//         color: #fff;
//     }
// }

@media screen and (max-width: 1200px) {
    .time-allocation-chart {
        padding: 15px;

        .canvas-container {
            canvas {
                max-height: 250px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .time-allocation-chart {
        padding: 12px;

        .time-allocation-title {
            font-size: 14px;
        }

        .canvas-container {
            padding: 5px;
            
            canvas {
                max-height: 200px;
                min-height: 150px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .time-allocation-chart {
        padding: 10px;

        .canvas-container {
            canvas {
                max-height: 180px;
                min-height: 120px;
            }
        }
    }
}