.sign-in-header {
    font-family: var(--font);
    font-size: 20px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: -0.02em;
    text-align: left;
}

.google-login-button {
    display: flex !important;
    padding: 10px var(--spacing-xl) !important;
    justify-content: center !important;
    align-items: center !important;
    gap: var(--spacing-lg) !important;
    align-self: stretch !important;
    border-radius: var(--radius-md) !important;
    border: 1px solid var(--gray-300) !important;
    background: var(--Colors-Background-bg-primary, #FFF) !important;

    .label {
        color: var(--colors-text-secondary-700, #344054);
        font-family: var(--Font-family-font-family-body);
        font-size: var(--Font-size-text-md);
        font-style: normal;
        font-weight: 600;
        line-height: var(--Line-height-text-md);
    }
}

.divider {
    display: flex;
    align-items: center;
    gap: 9px;
    align-self: stretch;
    color: var(--gray-500, #6B7280);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    /* 143.75% */
}

.text-field-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .forgot-cont {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .forgot-pass {
            font-size: 14px;
            color: var(--placeholder);
        }
    }
}

.login-btn {
    margin-top: 20px !important;
}

.bottom-text {
    margin-top: 20px !important;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

}