.time-management-chart {
    background-color: var(--background-color-2);
    color: var(--text-color-1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    
    .time-allocation-title {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }
    
    .canvas-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        position: relative;
        
        canvas {
            width: 100% !important;
            height: auto !important;
            max-height: 300px;
            min-height: 200px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .time-management-chart {
        padding: 15px;

        .canvas-container {
            canvas {
                max-height: 250px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .time-management-chart {
        padding: 12px;

        .time-allocation-title {
            font-size: 14px;
        }

        .canvas-container {
            padding: 5px;
            
            canvas {
                max-height: 200px;
                min-height: 150px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .time-management-chart {
        padding: 10px;

        .canvas-container {
            canvas {
                max-height: 180px;
                min-height: 120px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .time-management-chart {
       height: fit-content;
    }
}